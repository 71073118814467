export default class InputHandler {
  constructor(paddle, game) {
    let rightBtn = document.getElementById("rightButton");
    let leftBtn = document.getElementById("leftButton");

    rightBtn.addEventListener("touchstart", (event) => {
      console.log("mousedown");
      paddle.moveRight();
    });

    rightBtn.addEventListener("touchend", (event) => {
      console.log("mouseup");
      if (this.paddle.speed > 0) this.paddle.stop();
    });

    leftBtn.addEventListener("touchstart", (event) => {
      console.log("mousedown");
      paddle.moveLeft();
    });

    leftBtn.addEventListener("touchend", (event) => {
      console.log("mouseup");
      if (this.paddle.speed < 0) this.paddle.stop();
    });

    this.paddle = paddle;
    this.game = game;
    document.addEventListener("keydown", (event) => {
      switch (event.keyCode) {
        case 37:
          paddle.moveLeft();
          break;

        case 39:
          paddle.moveRight();
          break;

        case 27:
          game.togglePause();
          break;

        case 32:
          game.start();
          break;
      }
    });

    document.addEventListener("keyup", (event) => {
      switch (event.keyCode) {
        case 37:
          if (paddle.speed < 0) paddle.stop();
          break;

        case 39:
          if (paddle.speed > 0) paddle.stop();
          break;
      }
    });
  }

  // handleLeftButtonDown() {
  //   this.paddle.moveLeft();
  // }
  // handleLeftButtonUp() {
  //   if (this.paddle.speed < 0) this.paddle.stop();
  // }

  // handleRightButtonDown() {
  //   this.paddle.moveRight();
  // }
  // handleRightButtonUp() {
  //   if (this.paddle.speed > 0) this.paddle.stop();
  // }
}
