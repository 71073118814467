import { GAMESTATE } from "/src/game.js";
import Game from "/src/game.js";

let canvas = document.getElementById("gameScreen");
let ctx = canvas.getContext("2d");

canvas.width = canvas.offsetWidth;
canvas.height = canvas.offsetHeight;

const GAME_WIDTH = canvas.offsetWidth;
const GAME_HEIGHT = canvas.offsetHeight;

let game = new Game(GAME_WIDTH, GAME_HEIGHT);

let lastTime = 0;

// game.start();

canvas.addEventListener("click", () => {
  console.log("canvas click");
  if (
    game.gamestate === GAMESTATE.RUNNING ||
    game.gamestate === GAMESTATE.PAUSED
  ) {
    game.togglePause();
  } else if (game.gamestate === GAMESTATE.GAMEOVER) {
    console.log("reset game");
    game.resetGame();
  } else {
    game.start();
  }
});

// Timestamp передаст браузер
function gameLoop(timestamp) {
  // Определяем сколько времени прошло с момента прошлого кадра
  let deltaTime = timestamp - lastTime;

  // Фиксируем время текущего кадра
  lastTime = timestamp;

  // Очищаем экран для новой отрисовки
  ctx.clearRect(0, 0, GAME_WIDTH, GAME_HEIGHT);

  // Обновляем состояние игры
  game.update(deltaTime);

  // Рисуем новый кадр
  game.draw(ctx);

  // Запрашиваем следующую отрисовку при обновлении экрана браузером
  requestAnimationFrame(gameLoop);
}

requestAnimationFrame(gameLoop);
