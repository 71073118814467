import { detectCollision } from "./collisionDetection.js";

export default class Ball {
  constructor(game) {
    this.image = document.getElementById("img_ball");

    this.gameWidth = game.gameWidth;
    this.gameHeight = game.gameHeight;

    this.game = game;
    this.size = 16;
    this.reset();
  }

  reset() {
    const speedYVariants = [-2, 2]; // -2 летим вверх; +2 летим вниз
    const randomPositionX = _.random(this.size, this.gameWidth - 2 * this.size);
    this.position = { x: randomPositionX, y: 400 };
    this.speed = { x: 4, y: speedYVariants[_.random(0, 1)] };
  }

  draw(ctx) {
    ctx.drawImage(
      this.image,
      this.position.x,
      this.position.y,
      this.size,
      this.size
    );
  }

  update(deltaTime) {
    // const reduceRnd = _.random(0.8, 0.98, true);
    // const increaseRnd = _.random(1.02, 1.2, true);
    // this.position.x += this.speed.x * rnd;

    // let newSpeedX = this.speed.x;
    // if (newSpeedX > 8) {
    //   newSpeedX = newSpeedX * reduceRnd;
    // } else {
    //   newSpeedX = newSpeedX * increaseRnd;
    // }

    this.speed.y =
      this.speed.y < 0 ? _.random(-2, -4, true) : _.random(2, 4, true);

    this.speed.x =
      this.speed.x < 0 ? _.random(-4, -6, true) : _.random(4, 6, true);

    this.position.x += this.speed.x;
    this.position.y += this.speed.y;

    // this.position.y += this.speed.y >= Math.abs(2) ? 1 : this.speed.y * rnd;

    // wall on left or right
    if (this.position.x + this.size > this.gameWidth || this.position.x < 0) {
      // const rnd = _.random(0.8, 1, true);

      this.speed.x = -this.speed.x;
      console.log("xSpeed", this.speed.x);
    }

    // wall on top
    if (this.position.y < 0 + this.size / 2) {
      // const rnd = _.random(0.8, 1, true);
      this.speed.y = -this.speed.y;
      console.log("ySpeed", this.speed.y);
    }

    // bottom of game
    if (this.position.y + this.size > this.gameHeight) {
      this.game.lives--;
      this.reset();
    }

    if (detectCollision(this, this.game.paddle)) {
      this.speed.y = -this.speed.y;
      this.position.y = this.game.paddle.position.y - this.size;
    }
  }
}
